$blue: #04aded !default;
$brand-primary: $blue;
$link-color: $blue;
$body-bg: #F7F7F7;
$dropdown-link-hover-bg: $blue;
$dropdown-link-hover-color: #F7F7F7;
$font-family: 'Source Sans Pro', 'Helvetica Neue', sans-serif;
$icon-color: #93979A;
$icon-color-hover: #595a5a;
$navbar-dark-color: #E3E6E9;
$card-background: #f1f1f1;
$header-border-color: #D0D0D0;

$border-color: #f1f1f1;
$list-stripe-dark-color: #f8f9fa;
$user-group-title-color: #555555;
$user-group-highlight: #09bdff;


@import "node_modules/font-awesome/scss/font-awesome";
@import "node_modules/bootstrap/scss/bootstrap";
@import "font-awesome";
@import "bootstrap";

@import "./header";
@import "./user_groups";
@import "./configuration_sidebar";
@import "./configuration_layer_select";
@import "./import_data";
@import "./table_identify_configuration";
@import "./shapefile";

html {
    height: 100%;
}

body {
    height: 100%;
    font-family: $font-family;
    overflow-x: hidden;
    overflow-y: auto;
}

a:hover {
    text-decoration: none;
}

.alert:empty {
    display: none;
}

.row {
    width: 100%;
    margin-left: 0px;
}

.icon-size-15 {
    font-size: 20px;
}

.font-size-12 {
    font-size: 12px;
}

.icon-color {
    color: $icon-color;
}

.icon-color:hover {
    color: $icon-color-hover;
}

.card-customized {
    background-color: $card-background;
    padding: 30px;
    margin-top: 100px;
}
